import * as React from 'react'
import StarImage from "../images/star.svg"

function FiveStars(){
    return(
        <div className={"flex flex-row p-2"}>
            <img className={"h-7"} src={StarImage} alt={"Star icon"} />
            <img className={"h-7"} src={StarImage} alt={"Star icon"} />
            <img className={"h-7"} src={StarImage} alt={"Star icon"} />
            <img className={"h-7"} src={StarImage} alt={"Star icon"} />
            <img className={"h-7"} src={StarImage} alt={"Star icon"} />
        </div>
    )
}

function SingleReview(props){
    return(
        <div className={"border rounded p-4 m-2"}>
            <div className={"flex justify-between"}>
                <div>
                    <h1 className={"text-xl font-bold text-center font-black/90"}>{props.person}</h1>
                    <p className={"text-gray-500 text-center"}>{props.date}</p>
                </div>

                <div>
                    <FiveStars/>
                </div>
            </div>
            <p className={"leading-loose p-2 pt-1 mt-1 border-t"}>{props.children}</p>
        </div>
    )
}

function ReviewSection(){
    return(
        <div className={"dark:bg-gray-900 dark:text-white transition duration-500"}>
            <h1 className={"text-6xl font-bold text-center sm:py-32 py-20 dark:bg-gray-900 dark:text-white transition duration-500"}>What Our <span className={"text-malibu-500"}>Customers</span> Say About Us</h1>
            <div className={"max-w-5xl mx-auto grid grid-cols-1 sm:grid-cols-2 py-10 gap-10"}>
                <SingleReview person={"Amanda M"} date={"May 2022"}>Mari cleaned my apartment for a move out cleaning & she was amazing!! I would absolutely recommend her to any of my friends or family to help clean anytime. She was so professional, friendly & knew what she was doing to make it all look like new! She does very fair pricing & worth paying the money. If you want to get your apartment deposit back.. I’d recommend having her clean for you. Thank you Mari!!!(:</SingleReview>
                <SingleReview person={"Chrystal R"} date={"Apr 2022"}>Working with Maricela is a dream! I almost don't want to tell people how great she is because I only want her to work at our place 😂 She is responsive and fast. Our house always feels so clean!! She was able to get our shower looking nicer than it was when we moved in. Everything is just perfect. Can't recommend this business enough :) you won't regret working with RB maids!</SingleReview>
                <SingleReview person={"Barry G"} date={"May 2022"}>Mari cleaned our condo for the first time by herself. Her crew member was sick. She was amazing ! Our condo has never been this clean and my wife is a clean Freak. Mari was professional, had extreme attention to detail and was very pleasant. We are now using her services on a regular basis. We give her our highest recommendation.</SingleReview>
                <SingleReview person={"Colette G"} date={"Jan 2019"}>Mari and her crew were incredible!  We are selling our house and I needed deep and thorough cleaning to get ready to list the house.  She was easy to contact and schedule and arrived on time.  Her rates are reasonable and so worth her hard work.  I wish I wasn’t moving so I could hire her on a regular basis!</SingleReview>
                <SingleReview person={"Grazi Starr"} date={"Oct 2020"}>They did a fabulous job on cleaning my apartment! I would highly recommend them to anyone. My apartment was thoroughly cleaned and left in wonderful condition when I came back home. I would use them again.</SingleReview>
                <SingleReview person={"C.S."} date={"Jun 2020"}>I took a chance and called Maricella after reading about her service last month to see if she could do a move-in cleaning for me. Her reviews speak volumes - she knows her stuff. She came to see what needed to be done and gave us her quote and a couple of weeks later she came with her helper to do the job. She was thorough, professional and very courteous. Don't hesitate to call on Maricella for all your cleaning needs.
                    Thank you Maricella.</SingleReview>
            </div>
        </div>
    )
}

export default ReviewSection